import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ButtonPrimary from "../../components/buttonprimary"
import { Helmet } from "react-helmet"

export default function LearningPods() {
	return (
		<Layout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>JustPax Collective | Learning Pods Services</title>
				<link rel="canonical" href="http://justpaxcollective.com" />
			</Helmet>
			<section class="section--learningPods">
				<section class="wrapper">
					<div class="section--headline">
						<h1>Learning Pods</h1>
						<p>We understand that leaders of growing organizations work in a fast-paced environment. Our Learning Pods are designed with you in mind. Utilizing online tools, we design cohort experiences for cross-industry learning and context-specific applications. </p>
					</div>
					<div className="section--details flex-container">
						<div>
							<h2>Learn with community members.</h2>
							<p>Imagine if we, as parents, coaches, and friends, could start witnessing change in our lives and in our circles. It takes all of us to become a beloved community for all. Get equipped with others and advance the crucial work of justice and peacemaking on the grassroots level.</p>
							<p>6 weekly sessions</p>
							<p>Sign up for our next community learning pod, Spring 2021</p>
							<ButtonPrimary buttonLink="/contact-us" buttonText="Sign up"></ButtonPrimary>
						</div>
						<div>
							<h2>Learn with other leaders.</h2>
							<p>You can only lead others as far as you’ve led yourself. Gain crucial awareness, insight, and tools to lead your organization forward in diversity, equity, and inclusion.</p>
							<p>8 sessions, bimonthly in a hybrid format (online or in-person)</p>
							<p>Sign up for our inaugural learning pod, Spring 2021</p>
							<ButtonPrimary buttonLink="/contact-us" buttonText="Sign up"></ButtonPrimary>
						</div>
					</div>
				</section>
			</section>
		</Layout>
	)
}